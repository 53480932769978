import React from 'react'
import Layout from '../../../components/Layout'

const SuccessPage = () => {
  return (
    <Layout>

      <section className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-three-fifths is-offset-one-fifth'>
                <div className='section'>
                  <h1 className='title is-size-1 has-text-weight-bold'>
                    Checkout Securely Below
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className=''>
        <iframe
          src="https://checkoutpage.co/checkout/5ea053a58cce360015d0e825/flank-website-rewrite"
          width="100%"
          height="1200"
          frameborder="0"
        ></iframe>
      </div>
    </Layout>
  )
}

export default SuccessPage
